import React from 'react';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Typography from '@mui/material/Typography';
import Fab from "@mui/material/Fab";
import './Footer.scss';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";


interface INormalFooterActionsProps {
    history: any;
}

export function NormalFooterActions ({history} : INormalFooterActionsProps) {
    return (
        <React.Fragment>
            <BottomNavigationAction   
                className="nav left-nav"
                onClick={() => history.goBack()}
                icon={<Fab color="primary" component="div"><ChevronLeftIcon sx={{ color: "white"}}/></Fab>}
            />
            <BottomNavigationAction 
                onClick={() => {
                    history.push("/")
                
                }} 
                className={`nav center-nav home`}
                icon={
                    <Typography 
                        component="div"
                        variant="h1"
                        className="emoji"
                    > 🏠</Typography>
                }
            />
            <BottomNavigationAction  className="nav right-nav" onClick={() => history.push("/chats")} label="Nearby" icon={<Typography variant="h4" className="emoji" sx={{position: "relative", top: "5px"}}>💬</Typography>} />
        </React.Fragment>
    );
}