import React from 'react';
import Avatar from "@mui/material/Avatar";
import { useAppSelector } from "../../app/hooks";
import { IDictionary } from "../../models/common";
import { IProfileBrief } from "../../models/user";

interface IAvatarProps extends IDictionary<any> {
    profile: IProfileBrief
}

export function AvatarDisplay({profile, ...props}: IAvatarProps) {
    const photoURL = profile.profilePic;

    return (
        <Avatar {...props} alt={profile.name?.substr(0, 1)} src={photoURL}></Avatar>
    );
}


export function UserAvatar({children, ...props}: any) {
    const profile = useAppSelector(state => state.firebase.profile);
    return <AvatarDisplay {...props} profile={profile} />;
}