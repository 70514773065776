import { IDictionary } from '../models/common';
import intro from './introductory.json';
import alert from './alert.json';
import statements from './statements.json';

const en: IDictionary<string> = Object.assign({}, intro.en, alert.en, statements.en);

export { en };

export function getStatement(key: string): string {
    return en[key];
}