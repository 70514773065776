import React from 'react';
import Fab from '@mui/material/Fab';
import AddFriendIcon from '@mui/icons-material/PersonAddAlt1';
import CircularProgress from '@mui/material/CircularProgress';
import { Handler } from '../utils/Types';
import './FriendRequests.scss';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useFirebase } from 'react-redux-firebase';
import { useAppSelector } from '../../app/hooks';

interface IFriendRequestProps {
    isPrivate: boolean
    incomingState: any;
    incomingFriend: boolean;
    outgoingFriend: boolean;
    setOutgoingFriend: Handler<boolean>;
    incomingCaller: string;
    
}

export function FriendRequest({isPrivate: _isPrivate, incomingState, incomingFriend, incomingCaller, outgoingFriend, setOutgoingFriend}: IFriendRequestProps) {
    const firebase = useFirebase();
    const chats = useAppSelector(state => state.firebase.profile.chats);
    const _chats = chats || {};

    const [ showSnackbar, setShowSnackbar ] = React.useState({ open: incomingFriend, opened: incomingFriend });

    React.useEffect(() => {
        if (incomingFriend) {
            setShowSnackbar((_showSnackbar) => {
                if (!_showSnackbar.opened) {
                    return { open: true, opened: true};
                } else {
                    return _showSnackbar;
                }
            });
        }
    }, [incomingFriend]);

    React.useEffect(() => {
        const _chats = chats || {};
        
        if (
            incomingFriend
            && outgoingFriend
            && incomingState?.uid 
            && _chats[incomingState?.uid] === undefined 
            && !_chats[incomingState?.uid]?.expired
        ) {
            const { uid, name, profilePic } = incomingState;
            const participant = {uid, name, profilePic};
            const timestamp = new Date();

            firebase.updateProfile({ 
                chats: {
                    ..._chats,
                    [uid]: {
                        participant,
                        expired: false,
                        startDate: timestamp,
                        lastSent: timestamp,
                        lastSeen: timestamp,
                        unseenMessage: 0,                        
                        messages: [] 
                    }
                }
            });
        }
    }, [chats, firebase, incomingFriend, incomingState, outgoingFriend]);

    
    if (
        (incomingFriend && outgoingFriend)
        || (
            _chats[incomingState?.uid] !== undefined 
            || _chats[incomingState?.uid]?.expired
        )
     ) {
        return <React.Fragment/>;
    } else {
        let friendRequestClassName = "control friend-request-fab";
        if (incomingFriend) {
            friendRequestClassName += " incoming-friend-request pulse";
        }

        return (
            <Box className="friend-requests" >
                <Fab 
                    aria-label="Add Friend" 
                    color={(outgoingFriend? "secondary" : "primary" )}
                    onClick={() => { setOutgoingFriend(true); }}
                    className={friendRequestClassName}
                    disabled={outgoingFriend}
                >
                    <AddFriendIcon/>
                </Fab>    
                {outgoingFriend? <CircularProgress className="awaiting-friendship"/> : <React.Fragment/>}
                <Snackbar
                    className="snack-bar"
                    open={showSnackbar.open}
                    anchorOrigin={{ vertical: "top", horizontal: "center"}}
                    autoHideDuration={6000}
                    onClose={() => { setShowSnackbar(showSnackbar => ({...showSnackbar, open: false }))}}
                >
                    <Alert severity="info">{incomingCaller} wants to connect with you...</Alert>
                </Snackbar>
            </Box>
        );
    } 
    
}