import React from 'react';
import { useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { useAppSelector } from "../../app/hooks";
import { useHeading } from "../Header/useHeading";
import { AvatarDisplay } from "../utils/UserAvatar";
import './Shared.scss';
import { useSnackbar } from "../utils/useSnackbar";
import { useFirebase } from "react-redux-firebase";
import { IProfile } from "../../models/user";
import { Loading } from "../utils/Loading";
import { GenderSelect } from "../utils/GenderSelect";
import { IWizardMountProps } from "./shared";
import Button from "@mui/material/Button";


interface IProfileProps extends IWizardMountProps {
}

function ProfileRender({profile, ...props}: IProfileProps & { profile: IProfile }): JSX.Element {
    const firebase = useFirebase();
    const auth = useAppSelector(state => state.firebase.auth);

    const [snackbar, {setError, setSuccess}] = useSnackbar();
    const [picPreview, setPicPreview] = useState("");
    
    const [loading, setLoading] = useState(false);
    
    const [name, setName] = useState(profile.name || "");
    const [gender, setGender] = useState(profile.gender || "");

    const fileInput = useRef<any>(null);

    useHeading("Profile");

    return (
        <Box component="form"  onSubmit={async (e: any) => {
            e.preventDefault();
            
            setLoading(true);

            try {
                let newProfile: Partial<IProfile> = { name, gender }

                if (fileInput.current?.files && fileInput.current.files[0]) {
                    const downloadURLPromise = await firebase.uploadFile("/profile-pics/" , fileInput.current.files[0], undefined , {
                        name:auth.uid
                    }).then(a => a.uploadTaskSnapshot.ref.getDownloadURL());
                      
                    newProfile.profilePic = await downloadURLPromise;
                
                } else if ( !profile.profilePic &&  !picPreview ) {
                    setError("selectAProfilePic");
                    //eslint-disable-next-line
                    throw { code: "profile/nopic", message: "No Profile Pic" };
                }    
                
                await firebase.updateProfile({ ...newProfile, uid: auth.uid });
                setSuccess("savedSuccessfully");
                console.log("Success");
                if (props.onSubmit)  {
                    setTimeout((submit: () => any) => submit(), 10, props.onSubmit);
                }
                
            } catch (e: any) {
                console.error(e);
                switch(e.code) {
                    case "profile/nopic":
                        break;
                    default: 
                        setError("cannotUpdateProfile");
                }
            }
           
            setLoading(false);

        }}>
            {snackbar}
            <Box className="field-wrapper">
                <IconButton className="avatar" onClick={(e: any) => {
                    if(fileInput.current) {
                        fileInput.current.click()
                    }
                }}>
                    <AvatarDisplay profile={{...profile, profilePic: picPreview || profile.profilePic}} sx={{ width: "8rem", height: "8rem" }}/>
                </IconButton>
                <input ref={fileInput} type="file"  style={{display:"none"}} accept="image/*" onChange={() => {
                    const [file] = fileInput.current?.files || [];
                    if (file) {
                        setPicPreview(URL.createObjectURL(file));
                    }
                    
                }}/>
            </Box>

            <Box className="field-wrapper">
                <TextField 
                    required 
                    name="name"
                    id="name"
                    value={name}
                    label="Your Name"
                    color="secondary"
                    onChange={(e) => { setName(e.target.value); }}
                />
            </Box>

            <Box className="field-wrapper">
                <GenderSelect value={gender} setValue={setGender} />
            </Box>
            {loading? <Loading/>  : ""}

            {props.wizardNav || (
                <Button color="secondary" variant="contained" type="submit" sx={{mt: "2rem", float:"right"}}>Save</Button>
            )}
        </Box>
    );
}


export function Profile(props: IProfileProps) {
    const profile = useAppSelector(state => state.firebase.profile);
    if (profile.isLoaded) {
        return <ProfileRender profile={profile} {...props}/>;
    } else {
        return <Loading/>;
    }
}