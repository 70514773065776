import React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AppBar from "@mui/material/AppBar";
import Toolbar from '@mui/material/Toolbar';
import { ClassedTypography } from "../utils/Typography";
import IconButton from '@mui/material/IconButton'
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from '../../app/hooks';
import { AvatarDisplay } from '../utils/UserAvatar';
import './Header.scss';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MoreVert from '@mui/icons-material/MoreVert';

interface IChatHeaderProps {}

export function ChatHeader(props: IChatHeaderProps): JSX.Element {
    const { participantId }  = useParams<{ [key: string]: string }>();
    const chat = useAppSelector(state => state.firebase.profile.chats[participantId]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const history = useHistory();

    return (
        <AppBar position="fixed">
            <Toolbar>
            <IconButton
                size="medium"
                edge="start"
                aria-label="back"
                onClick={() => {
                    history.goBack();
                }}
                sx={{mr: "1.2rem"}}
            >
                <ChevronLeftIcon sx={{color:"white"}}/>
            </IconButton>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                    
                }}
                sx={{mr: "1rem"}}
            >
                <AvatarDisplay profile={chat.participant} />
            </IconButton>
            <ClassedTypography
                variant="h6"
                component="div"
                color="white"
                sx={{ flexGrow: 1 }}
                text={chat.participant.name || ""}
            />
            
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="default"
                    sx={{color: "white"}}
                >
                    <MoreVert/>
                </IconButton>
                
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={()=> {
                        handleClose();
                    }}>View Profile</MenuItem>
                    <MenuItem onClick={()=> {
                        handleClose();
                    }}>Unfriend</MenuItem>
                    <MenuItem onClick={()=> {
                        handleClose();
                    }}>Block</MenuItem>
                    <MenuItem  onClick={()=> {
                        handleClose();
                    }}>Sign Out</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}