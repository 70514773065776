import React from 'react';
import MUITypography, {TypographyTypeMap} from '@mui/material/Typography';
import { ClassedText } from './ClassedText';

interface ITypographyProps {
    text: string
}

export function ClassedTypography({text, ...props}: ITypographyProps & TypographyTypeMap<any, any>["props"]) {
    return (
        <MUITypography {...props}>
            <ClassedText text={text}/>
        </MUITypography>
    );
}