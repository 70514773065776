import React from 'react';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Typography from '@mui/material/Typography';
import Fab from "@mui/material/Fab";
import './Footer.scss';
import { useAppSelector } from '../../app/hooks';
import { genderSymbol } from '../utils/Gender';
import { getRegionDetails } from '../utils/Region';
import Badge from '@mui/material/Badge';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import StopIcon from "@mui/icons-material/Stop";
import HomeIcon from "@mui/icons-material/Home";
 
const _window: any = window;

interface IPublicFooterActionsProps {
    history: any;
    pathname: string
}

export function PublicFooterActions ({history, pathname} : IPublicFooterActionsProps) {
    const profile = useAppSelector(state => state.firebase.profile);
    const gender = genderSymbol(profile.preferredGender);
    const region = getRegionDetails(profile.preferredRegion);
    let messageCount = 0;
    Object.values(profile.chats || {}).forEach(chat => messageCount += chat.unseenMessage);

    return (
        <React.Fragment>
            <BottomNavigationAction   className="nav left-nav" onClick={() => history.push("/preference")} icon={<Typography className="emoji" variant="h3">{gender}</Typography>}/>
            <BottomNavigationAction 
                onClick={() => {
                    if (
                        pathname === "/"
                        || pathname.includes("/public/connect/")
                    ) {
                        if (typeof _window.unblock === "function") _window.unblock();
                        history.push("/public");
                    } else  {
                        history.push("/")
                    }
                }} 
                className="nav  center-nav"
                icon={<Fab component="div" color={ pathname === "/public" ? "secondary" : "primary" }  sx={{p: "4rem"}}>
                { pathname === "/"? (
                        <Typography 
                        component="div"
                        variant="h1"
                        className="emoji"
                    >{region?.Icon}</Typography>
                ) : (
                    <Typography variant="h1" color="white" fontSize="4rem">{ 
                        pathname.includes("/public/connect/") ? <ChevronRightIcon className="home-icon"/> 
                        : pathname === "/public" ?  <StopIcon className="home-icon"/>
                        : <HomeIcon  className="home-icon"/>
                        }</Typography>
                )}
                </Fab>} />
            <BottomNavigationAction  
                className="nav right-nav"
                onClick={() => history.push("/chats")}
                label="Nearby"
                icon={<Badge badgeContent={messageCount} color="primary"><Typography variant="h4" className="emoji" sx={{position: "relative", top: "5px"}}>💬</Typography></Badge>}
            />
        </React.Fragment>
    );
}