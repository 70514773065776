import React from 'react';
import Box from '@mui/material/Box';
import { ClassedTypography } from "../utils/Typography";
import { useRef } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { getStatement } from '../../strings';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/ArrowLeft'
import { useSnackbar } from '../utils/useSnackbar';
import { useHistory } from 'react-router';

interface IRecoverPasswordProps {
    inputVariant: "filled" | "standard" | "outlined"
}

export function RecoverPassword({inputVariant}: IRecoverPasswordProps) {
    const firebase = useFirebase();
    const history = useHistory();

    const [snackbar, {setError, setSuccess}] = useSnackbar();
    
    let emailInput = useRef<any>(null);
    
    return (
        <Box sx={{pr: { xl:8, lg: 8, md: 8, sm: 0}, py: 6}} component="form" onSubmit={(e :any) => {
            e.preventDefault();
            let email = emailInput.current?.value;
            
            if (typeof email === "string") {
                firebase.resetPassword(email).then(() => {
                    setSuccess("passwordRecoverySuccess");
                }).catch(e => {
                    setError("passwordRecoveryError");
                });
                
            }
        }}>
            {snackbar}
            <Button onClick={() => {history.push("/login")}} startIcon={<BackIcon/>} sx={{mb: 3}}>
                {getStatement("goBack")}
            </Button>

            <ClassedTypography variant="h6" component="h3" className="field" text={getStatement("recoverAccountPrompt")}/>
            <Box className="field">
                <TextField required inputRef={emailInput} id="email" type="email" label="Email Address" name="email" variant={inputVariant} />
            </Box>

            <Box className="field">
                <Button type="submit">{getStatement("recoverAccount")}</Button>
            </Box>
        </Box>
    );
}