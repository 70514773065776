import { ILabeledValue } from './NamedValue';
import MenuItem from '@mui/material/MenuItem';
import { ClassedTypography } from "../utils/Typography";

export function MenuItems(menuItems: Array<ILabeledValue>) {
    return menuItems.map(menuItem => (
        <MenuItem key={menuItem.value} value={menuItem.value}>
            <ClassedTypography variant="h6" text={menuItem.label}/>
        </MenuItem>
    ));

}