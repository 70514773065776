import React from 'react';
const log: any = () => {};
interface IAudioProps {
    id: string;
    srcObject?: MediaStream;
}
export default function ReactAudio({id, srcObject}: IAudioProps) {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        log("enumerateDevices() not supported.");
    } else {  
        // List cameras and microphones.
        
        navigator.mediaDevices.enumerateDevices()
        .then(function(devices) {
        devices.forEach(function(device) {
            if (device.kind === "audiooutput")
            log(device.kind + ": " + device.label +
                        " id = " + device.deviceId);
        });
        })
        .catch(function(err) {
            log(err.name + ": " + err.message);
        });
    }
      
    return <audio id={id} ref={(audio) => { if (audio) audio.srcObject = srcObject || null; }}  autoPlay={true}/>;


}