import React from 'react';
import List from '@mui/material/List';
import { useAppSelector } from '../../app/hooks';
import { ChatBrief } from './ChatBrief';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router';
import Divider from '@mui/material/Divider';

interface IChatsProps {}

export function Chats(props:IChatsProps) {
    const userChats = useAppSelector(state => state.firebase.profile.chats);
    const history = useHistory();
    const chats = Object.values(userChats || {});
    
    if(chats.length === 0 ) return (
        <Box sx={{py: "3rem"}}>
            <Typography variant="h3" sx={{ mb: "1.3rem"}}>No Chats Available</Typography>
            <Button onClick={() => history.push("/")}>Start Chatting</Button>
        </Box>
    );
    else return (
        <List sx={{ width: "100%"}}>
            {chats.map(chat => (
                <React.Fragment key={chat.participant.uid}>
                    <ChatBrief chat={chat}/>
                    <Divider variant="inset" component="li" />
                </React.Fragment>
            ))}        
        </List>
    );
}