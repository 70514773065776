import React from 'react';
import './App.scss';
import { Route, Switch } from 'react-router';

import { PrivateRoute } from './features/Auth/PrivateRoute';
import { LoginPage } from './features/Auth/LoginPage';
import { Logout } from './features/Auth/Logout';
import { Header } from './features/Header/Header';
import { Footer } from './features/Footer/Footer';
import { Profile } from './features/Profile/Profile';
import { ProfileWizard } from './features/Profile/ProfileWizard';
import { Subscription } from './features/Profile/Subscription';
import { Preference } from './features/Profile/Preference';
import Container from '@mui/material/Container';
import { ProfileChecklist } from './features/Profile/ProfileChecklist';
import { Chats } from './features/Chat/Chats';
import { Chat } from './features/Chat/Chat';
import { Home } from './features/Public/Home';
import { Public } from './features/Public/Public';

function App() {
  return (
    <Switch>
      <Route path="/login" component={LoginPage}/>
      <Route path="/logout" component={Logout}/>

      <PrivateRoute path="/profile-wizard">
        <ProfileWizard/>
      </PrivateRoute>
      
      <PrivateRoute path="/">
        <ProfileChecklist>
          <Header/>
          <Container sx={{py: "calc(72px + 2.1rem)", minHeight: "100vh", display:"flex", justifyContent: "center"}}>
          <Switch>
            <Route path="/profile" component={Profile}/>
            <Route path="/preference" component={Preference}/>
            <Route path="/subscription" component={Subscription}/>
            <Route path="/chats/:participantId" component={Chat} />
            <Route path="/chats" component={Chats} exact/>
            <Route path="/public" component={Public}/>
            <Route path="/" component={Home}/>
          </Switch>
          </Container>
          <Footer/>
        </ProfileChecklist>
      </PrivateRoute>

    </Switch>
  );
}

export default App;
