import React from 'react';
import { useState } from 'react';
import { useHistory, Switch, Route } from 'react-router';
import AppBar from "@mui/material/AppBar";
import Toolbar from '@mui/material/Toolbar';
import { ClassedTypography } from "../utils/Typography";
import IconButton from '@mui/material/IconButton'
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from '../../app/hooks';
import { selectTitle } from './headerSlice';
import { Logo } from '../utils/Logo';
import { UserAvatar } from '../utils/UserAvatar';
import './Header.scss';
import { ChatHeader } from './ChatHeader';

interface IHeaderProps {}

export function Header(props: IHeaderProps): JSX.Element {
    const title = useAppSelector(selectTitle);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const history = useHistory();
    return (
        <Switch>
        <Route path="/chats/:participantId" component={ChatHeader}/>
        <Route path="/">
            
        <AppBar position="fixed">
            <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                    history.push("/");
                }}
            >
                <Logo />
            </IconButton>
            <ClassedTypography
                variant="h6"
                component="div"
                color="white"
                sx={{ flexGrow: 1 }}
                text={title || ""}
            />
            
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="default"
                >
                    <UserAvatar/>
                </IconButton>
                
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={()=> {
                        history.push("/profile");
                        handleClose();
                    }}>Profile</MenuItem>
                    <MenuItem onClick={()=> {
                        history.push("/preference");
                        handleClose();
                    }}>Preference</MenuItem>
                    <MenuItem onClick={()=> {
                        history.push("/subscription");
                        handleClose();
                    }}>Subscription</MenuItem>
 
                    <MenuItem  onClick={()=> {
                        history.push("/logout");
                        handleClose();
                    }}>Sign Out</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
        </Route>
        </Switch>
    );
}