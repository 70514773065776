import React from 'react';
import BottomNavigation from "@mui/material/BottomNavigation";
import Box from "@mui/material/Box";
import { Route, Switch, useHistory, useLocation } from "react-router";
import './Footer.scss';
import { PublicFooterActions } from './PublicFooter';
import { NormalFooterActions } from './NormalFooter';

interface IFooterProps {}


export function Footer(props: IFooterProps) {
    const history = useHistory();
    const location = useLocation();
    
    if (new RegExp("/chats/(?:\\w|\\d)").test(location.pathname)) {
        return <React.Fragment />;
    }
    
    const publicFooterActions = <PublicFooterActions history={history} pathname={location.pathname}/>;
    
    return (
        <Box sx={{ position: "fixed", bottom: "0px", width: "100%", height: "72px"}}>
            <BottomNavigation className="nav bottom-nav"> 
                <Switch>
                    <Route path="/public">{publicFooterActions}</Route>
                    <Route path="/" exact>{publicFooterActions}</Route>
                    <Route path="/"><NormalFooterActions history={history}/></Route>
                </Switch>
            </BottomNavigation>
        </Box>
    )

}
