import firebaseCompat from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import fbConfig from '../shadow.json';
import databases from '../database.json';

// Initialize firebase instance
firebaseCompat.initializeApp(fbConfig);

databases.forEach(([option, name]: any[]) => firebaseCompat.initializeApp({ ...fbConfig, ...option}, name));

export { firebaseCompat as firebase };