import React from 'react';
import {Fragment} from 'react';
import { Redirect } from 'react-router';
import { useAppSelector } from "../../app/hooks";
import { genderOptions } from "../utils/Gender";
import { Loading } from "../utils/Loading";
import { getRegionDetails } from '../utils/Region';

const genderVals = genderOptions.map(g => g.value);

interface IProfileChecklistProps {
    children: React.ReactNode;
}

export function ProfileChecklist({children}: IProfileChecklistProps) { 
    const auth = useAppSelector(state=> state.firebase.auth);
    const profile = useAppSelector(state=> state.firebase.profile);
    
    if (!profile.isLoaded) {
        return <Loading/>
    }

    let isProfileComplete: boolean = true;
    isProfileComplete = isProfileComplete && profile.uid === auth.uid;
    isProfileComplete = isProfileComplete && !!profile.profilePic && profile.profilePic.length > 3;
    isProfileComplete = isProfileComplete && !!profile.name?.length;
    isProfileComplete = isProfileComplete && genderVals.includes(profile.gender);
    isProfileComplete = isProfileComplete && getRegionDetails(profile.preferredRegion) !== undefined;
    isProfileComplete = isProfileComplete && genderVals.includes(profile.preferredGender);
    
    if (isProfileComplete) {
        return <Fragment>{children}</Fragment>
    } else {
        return (
            <Redirect
                to={{
                    pathname: "/profile-wizard",
                }}
            />
        );
    }
    
}