import { ILabeledValue, NamedValue } from "./NamedValue";

export const genderOptions: Array<ILabeledValue> = [
    NamedValue("male", "~emoji{♂️} Male"),
    NamedValue("female", "~emoji{♀️} Female"),
    NamedValue("trans", "~emoji{⚧️} Trans"),
];

export function genderSymbol(gender: string) {
    switch (gender) {
        case "male":
            return "♂️";
        
        case "female":
            return "♀️";

        default:
            return "⚧️";
            

    }
}
