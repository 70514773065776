import React from 'react';
import { useEffect } from "react";
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router";
import { Loading } from "../utils/Loading";

export function Logout() {
    const firebase = useFirebase();
    const history = useHistory();

    useEffect(()=> {
        firebase.logout();
        setTimeout(() => history.push('/'), 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return <Loading/>;
}
