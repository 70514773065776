import React from 'react';
import { RouteProps,  } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { isLoaded, isEmpty } from 'react-redux-firebase'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded

export interface IPrivateRouteProps extends RouteProps<string>{
    children: React.ReactNode;
}

export function PrivateRoute({ children, ...rest }: IPrivateRouteProps) {
  const auth = useAppSelector(state => state.firebase.auth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
