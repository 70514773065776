import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from '../../app/store';

export interface IHeaderState {
  title: string;
}

const initialState: IHeaderState = {
  title: "Love Bird"
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
  },
});

export const { setTitle } = headerSlice.actions;

export const selectTitle = (state: IRootState) => state.header.title;


export default headerSlice.reducer;