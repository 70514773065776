import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface ILoadingProps {
    noBackground?:boolean;
}

export function Loading({noBackground}: ILoadingProps) {
    if (noBackground) {
        return (
            <Box sx={{
                position: 'fixed',
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                top: "0px",
                left:"0px"
            }}>
                <CircularProgress color="primary"  sx={{zIndex: 991}} />
            </Box>
        );
    } else {
        return (
            <Backdrop open={true} sx={{zIndex: 999, backgroundColor: "rgba(255,255,255,0.89)" }}>
                <CircularProgress color="primary"  sx={{zIndex: 991}} />
            </Backdrop>
        );
    }
}