import React from 'react';

interface IVideoProps {
    id: string;
    muted?: boolean;
    srcObject?: MediaStream;
}
export default function ReactVideo({id, muted, srcObject}: IVideoProps) {
    
    const video = React.useMemo(() => (
        <div id={`${id}-container`} className="video-container">
            <video id={id} className="video" autoPlay={true} muted={muted} playsInline={true} ref={video => { if (video) video.srcObject =  srcObject || null; }}></video>
        </div>
    ), [id, muted, srcObject]);
    return video;


}