import React from 'react';
import { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ClassedTypography } from "../utils/Typography";
import Button from "@mui/material/Button";
import { Profile } from "./Profile";
import { Preference } from "./Preference";
import { Subscription } from "./Subscription";
import { useHistory } from "react-router";
import Container from "@mui/material/Container";

interface IProfileWizardProps {}

const steps = ['Profile', 'Preferences', 'Finishing'];
const subtitles = [ 'Setting up Profile', 'Setting up your Preference', 'Finalizing' ];

export function ProfileWizard(props: IProfileWizardProps) {
    const history = useHistory();

    const [activeStep, setActiveStep] = useState(0);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const onSubmit = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep + 1 >= steps.length) {
            history.push("/");
        }
    };

    const wizardNav = (
        <Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button type="submit" color="secondary">
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </Box>
        </Fragment>
    );

    const formProps = { onSubmit, wizardNav };
    const FormElement = activeStep >= 2 ? Subscription : activeStep === 1 ? Preference : Profile;
    
    return (
        <Container sx={{ pt: "3rem" }}>
            <ClassedTypography variant="h6"
                text={"Please Complete your Profile to Continue."}
            />
            <ClassedTypography sx={{ mt: 2, mb: 1 }} text={subtitles[activeStep]}/>
            <Box sx={{py: 2}} />
            <FormElement {...formProps}/>
            <Box sx={{height: "3rem"}}/>
            <Box sx={{ position: "fixed", bottom: "0px", height: "3rem", display: "flex", width: "100%", backgroundColor: "white", zIndex: 777}}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>
            </Box>

            
        </Container>
    )
}
