import React from 'react';
import { DataSnapshot } from '@firebase/database-types';
import { useRegionalRequestRef } from '../utils/PublicRequests';
import { IPublicConnectionProps } from './Types';

interface IActiveSearchProps extends IPublicConnectionProps { }

export function useActiveSearch({ profile, uid, candidateRef, setCandidateRef }: IActiveSearchProps) {
    const ref = useRegionalRequestRef(profile.preferredRegion, profile.preferredGender, profile.gender);
    
    // checking candidate actively
    React.useEffect(() => {
        if (!ref || candidateRef) return;

        let active =  true;
        const checkCandidate = (candidateSnapshot: DataSnapshot)  => {
            let possibleCandidate = candidateSnapshot.val();    
            if (
                active
                && possibleCandidate.uid !== uid
            ) {
                candidateSnapshot.child("request").ref.set({
                    uid: uid,
                    timestamp: Date.now()
                });
                setCandidateRef({
                    ref: candidateSnapshot.ref,
                    active: true
                });
            }
        };

        ref.on('value', ((snapshot: DataSnapshot) => {
            if(snapshot.hasChildren()) {
                snapshot.forEach(checkCandidate);
            }
        }));


        return () => {
            active = false;
            ref.off('child_added');
        }
        
    });

}