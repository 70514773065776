import React from 'react';
import firebase from 'firebase/compat/app';

import { useAppSelector } from '../../app/hooks';
import { getRegionDetails } from './Region';

export const publicRequestDir = 'publicRequests/';

export function useRegionalRequestRef(regionCode: string, ...subDirectory: string[]) {
    const auth = useAppSelector(state => state.firebase.auth);

    const directory: string = React.useMemo(() => subDirectory.join("/"), [subDirectory]);
    const region = React.useMemo(() => getRegionDetails(regionCode), [regionCode]);

    const ref = React.useMemo(() => {
        if (!auth.uid) {
            return undefined;
        }
        for (let c = 0; c < firebase.apps.length; c++) {
            const app = firebase.apps[c];
            
            if (app.name === region?.Server) {
                const ref = `${publicRequestDir}${directory}`;

                
                const database = firebase.database(app);
                database.goOnline();
                return database?.ref(ref);
            }
        }
        return undefined;
    }, [region, directory, auth]);

    return ref;
    
}