import React from 'react';
import { State } from './Types';

const getUserMedia:any = require('getusermedia');

export interface IStream {
    audioStream?: MediaStream;
    videoStream?: MediaStream;
}

const log: any = () => {};

export  function useUserMedia()
: [
    IStream,
    State<boolean>,
    State<boolean>,
    any
] {
    const [ audioStream, setAudioStream ] = React.useState<MediaStream | undefined>();
    const [ videoStream, setVideoStream ] = React.useState<MediaStream | undefined>();
    
    const [video, setVideo] = React.useState<boolean>(false);
    const [mute, setMute] = React.useState<boolean>(false);

    
    const [ error, setError ] = React.useState<any>();

    // synching mute to stream
    React.useEffect(() => {
        if (audioStream) {
            audioStream.getAudioTracks().forEach(track => {
                track.enabled = !mute;
            });
        }
    }, [mute, audioStream]);

    // setting audio stream
    React.useEffect(() => {
        if (audioStream) return;
        let active = true;
        
        const getMedia = (constraints: MediaStreamConstraints = {}) => getUserMedia({ audio: true, video: false, ...constraints }, function (err: any, stream: any) {
            // if the browser doesn't support user media
            // or the user says "no" the error gets passed
            // as the first argument.
            if (err) {
               setError(err);
               console.error(err);
            } else {
                if (active) {
                    log("setting userMedia");
                    setAudioStream(stream);
                }
            }
        });
        // workaround for android chrome bug
        if (
            navigator.userAgent.includes("Android")
            && navigator.userAgent.includes("Chrome/")
            && typeof navigator.mediaDevices?.enumerateDevices === "function"
        ) {
            log("fixing audio output bug");    
            getUserMedia({audio: true}, () => {
                if (active)
                navigator.mediaDevices.enumerateDevices().then(mediaDevices => {
                    if (active) {
                        if (mediaDevices.every(mediaDevice => {
                            if ( mediaDevice.kind === "audioinput" && mediaDevice.label.toLowerCase().includes("speaker")) {
                                getMedia({ audio: { deviceId: mediaDevice.deviceId } });
                                return false;
                            } else {
                                return true;
                            }
                        })) {
                            getMedia();
                        }
                    }
                }) 
            })
        } else {
            getMedia();
        }


        return () => {
            active = false;
        }
        
    }, [audioStream]);

    // getting video tracks
    React.useEffect(() => {
        if (!video || videoStream) return;
        // if we have userMedia and video is true but no videoTracks
        
       let active = true;

        getUserMedia({ audio: false, video: true }, function (err: any, stream: MediaStream) {
            if (err) {
               setError(err);
               console.error(err);
            } else if (active) {
                setVideoStream(stream);
            }
        });

        return () => {
            active = false;
        }
        
    }, [video, videoStream]);


    return [
        { 
            audioStream,
            videoStream
        }, 
        [video, setVideo],
        [mute, setMute],  
        { error }
    ];
}