import regions from './regions.json';

export function getRegionDetails(code: string) {
    for(let c = 0; c< regions.length; c++) {
        if (regions[c].Code === code) {
            return regions[c];
        }
    }

    return undefined;
}