import React from 'react';
import { IChatBrief } from "../../models/chat";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { useHistory } from 'react-router';


interface IChatBriefProps {
    chat: IChatBrief
}

export function ChatBrief({chat}: IChatBriefProps) {    
    const participant = chat.participant;
    const history = useHistory();
    const messageCount: number = chat.unseenMessage;
    
    return (
        <ListItemButton 
            onClick={() => history.push("/chats/" + participant.uid)}
        >    
            <ListItemAvatar>
                <Avatar src={participant.profilePic} />
            </ListItemAvatar>
            
            <ListItemText primary={participant.name} />
            <ListItemSecondaryAction>
                <Avatar component="span" sx={{ bgcolor: "#ff5722" }}>{messageCount}</Avatar>
            </ListItemSecondaryAction>
        </ListItemButton>
        
    
    );
}