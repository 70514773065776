import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
import { getStatement } from '../../strings';

type Severity = "error" | "warning" | "info" | "success";
type SnackbarHandler = (statementKey: string) => any;

interface ISnackbarProps {
    severity: Severity;
    statement: string;
}

interface ISnackbarEvents {
    setError: SnackbarHandler;
    setWarning: SnackbarHandler;
    setInformation: SnackbarHandler;
    setSuccess: SnackbarHandler;
    setMessage: (props: ISnackbarProps) => any;
}

type UseSnackbarOutput = [JSX.Element, ISnackbarEvents];

const defaultSnackbar: ISnackbarProps = { severity: "info", statement: ""};

export function useSnackbar(): UseSnackbarOutput {
    const [snackbarProps, setSnackbarProps] = useState<ISnackbarProps>(defaultSnackbar);
    const events: ISnackbarEvents = {
        setError: (key) => setSnackbarProps({ severity: "error", statement: getStatement(key)}),
        setWarning: (key) => setSnackbarProps({ severity: "warning", statement: getStatement(key)}),
        setInformation: (key) => setSnackbarProps({ severity: "info", statement: getStatement(key)}),
        setSuccess: (key) => setSnackbarProps({ severity: "success", statement: getStatement(key)}),
        setMessage: setSnackbarProps
    };

    const onClose = () => setSnackbarProps(defaultSnackbar);
    return [
        (
            <Snackbar 
                anchorOrigin={{ vertical: "bottom",  horizontal: "center" }}
                open={snackbarProps.statement.length > 0}
                autoHideDuration={6000}
                onClose={onClose}
            >
                <Alert onClose={onClose} severity={snackbarProps.severity} sx={{ width: '100%' }}>
                    {snackbarProps.statement}
                </Alert>
            </Snackbar>
        ), events
    ]
}