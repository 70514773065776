import React from 'react';

interface IClassedTextProps {
    text: string;
}

export function ClassedText({text}: IClassedTextProps) {
    let classStart = false;
    let codeStart = false;

    if (!text) {
        text="";
    }
    let className = '';
    let code = '';
    let classedTexts: Array<any> = [];

    for(let c = 0; c < text.length; c++) {
        
        let char = text[c];

        let escaped = c > 0 && text[c - 1] === "\\";

        if (
            !codeStart && !classStart &&
            char === "~" && (c === 0 && !escaped)
        ) {
            if (code) {
                classedTexts.push(code);
            }
            classStart = true;  
        } else if ( char === "{" && !escaped ) {
            classStart = false;
            codeStart = true;
        }  else if (classStart) {
            className += char;
        } else if (char === "}" && !escaped) {
            classStart = false;
            codeStart = false;

            classedTexts.push({
                className,
                code
            });
            
            className = "";
            code = "";

        } else if (char !== "\\" || escaped) {
            code += char;
        }
    }

    if (code) {
        classedTexts.push(code);
    }
    return (<React.Fragment>
        {classedTexts.map((classedText, idx) => {
            if (typeof classedText === "string")
                return (
                    <React.Fragment key={idx}>{classedText}</React.Fragment>
                )
            else 
                return (
                    <span key={idx} className={classedText.className}>
                        {classedText.code}
                    </span>
                );
        })}
    </React.Fragment>);
}