import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';

import './LoginPage.scss';
import Container from '@mui/material/Container';
import { LoginForm } from './LoginForm';
import { RegisterForm } from './RegisterForm';
import background from '../../assets/images/login-background.jpg';
import { RecoverPassword } from './RecoverPasswors';
import { Route, Switch, useHistory } from 'react-router';
import { ClassedTypography } from "../utils/Typography";
import { getStatement } from '../../strings';
import { useAppSelector } from '../../app/hooks';
import { isEmpty,isLoaded } from 'react-redux-firebase';
import { Loading } from '../utils/Loading';
import { Logo } from '../utils/Logo';


interface ILoginPageProps {

}

export function LoginPage(props: ILoginPageProps): JSX.Element {
  const auth = useAppSelector(state => state.firebase.auth);
  const history = useHistory();

  if (isLoaded(auth) && !isEmpty(auth)) {
      setTimeout(() => history.push("/"), 10);
      return (<Loading/>);
  }

  return (
    <Box className="login-page" sx={{ width: "100%", height: "100vh", backgroundImage: 'url('+background+')', backgroundSize: "cover"}}>  
      <Backdrop open={true}>
        <Container>
          <Box sx={{width: "100%", height: "100vh", my: 5, overflowY: "auto"}}>
          <Paper variant="elevation"> 
            <Box className="form" sx={{px: 6, py: 9}}>   
              
              <Grid container  alignItems="center">
                <Grid item md={6} sm={12}>
                  <Grid container className="logo">
                      <Grid item>
                        <Logo/>
                     </Grid>
                      <Grid item>
                      <ClassedTypography variant="h4" text={getStatement("appTitle")}/>
                      <ClassedTypography variant="subtitle2"  text={getStatement("appSubTitle")}/>
                      </Grid>
                  </Grid>
                  <Switch>
                    <Route path="/login/recover">
                      <RecoverPassword inputVariant="standard" />
                    </Route>
                    <Route path="/login" exact>
                      <LoginForm inputVariant="standard"/>
                    </Route>
                  </Switch>
                  
                </Grid>
                <Grid item md={6} sm={12}>
                  <RegisterForm inputVariant="standard"/>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          </Box>
        </Container>
      </Backdrop>
    </Box>
  );
}