import { ThunkAction, Action, createStore, combineReducers } from '@reduxjs/toolkit';
import { FirebaseReducer, firebaseReducer } from 'react-redux-firebase';
import { ISchema } from '../models/schema';
import { IProfile } from '../models/user';
import headerReducer, { IHeaderState } from '../features/Header/headerSlice';


export interface IRootState {
  firebase: FirebaseReducer.Reducer<IProfile, ISchema>;
  header: IHeaderState;
}

// Initialize other services on firebase instance
// firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers<IRootState>({
  firebase: firebaseReducer,
  header: headerReducer
});

export const store = createStore(rootReducer);


export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IRootState,
  unknown,
  Action<string>
>;
