import React from 'react';
import Peer from 'peerjs';
import Box from '@mui/system/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import MuteIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import VideoCam from '@mui/icons-material/Videocam';
import VideoCamOff from '@mui/icons-material/VideocamOff';
import { useMediaConnection } from './MediaConnection';
import { Handler } from '../utils/Types';
import ReactAudio from './Audio';
import "./Call.scss";
import ReactVideo from './Video';
import { IParticipantState } from './Types';
import { Loading } from '../utils/Loading';
import { IStream } from './UserMedia';

function hasVideo(stream?: MediaStream) {
    if (typeof stream?.getVideoTracks === "function") {
        return stream?.getVideoTracks().some(track => track.kind === "video") || false;
    } else {
        return false;
    }
}

interface IOutgoingStreamProps {
    outgoingState: any;
    outgoingVideoStream?: MediaStream;
}
function OutgoingStream({ outgoingState, outgoingVideoStream: outgoingStream }: IOutgoingStreamProps) {
    if (outgoingState.video && hasVideo(outgoingStream)) {
        return (
            <Box className="video-group">
                <ReactVideo muted={true} id="outgoing-vid-stream" srcObject={outgoingStream} />
            </Box>
        );
    } else {
        return <React.Fragment/>;
    }
}


interface IIncomingStreamProps {
    incomingState: any;
    incomingStream: IStream;
}
function IncomingStream({ incomingStream, incomingState }: IIncomingStreamProps ) {
    if (hasVideo(incomingState.video && incomingStream?.videoStream)) {
        return (
            <Box className="video-group">
                <ReactAudio id="incoming-audio" srcObject={incomingStream?.audioStream}/>
                <ReactVideo id="incoming-vid-stream" srcObject={incomingStream.videoStream} />
            </Box>
        );
    } else {
        return (
            <React.Fragment>
                <Box sx={{ mb: "1.6rem" }}>
                    <ReactAudio id="incoming-audio" srcObject={incomingStream?.audioStream}/>
                    <Avatar  
                        alt={incomingState?.name?.substr(0, 1)}
                        src={incomingState?.profilePic} 
                        sx={{
                            width: "8em",
                            height: "8em",
                            display: "block",
                            margin: "auto"
                        }}
                    />
                </Box>
                <Box sx={{ mb: "1.6rem" }}>
                    <Typography  variant="h5" sx={{textAlign: "center"}}>{incomingState?.name}</Typography>
                </Box>
            </React.Fragment>
        );
    }
}

interface ICallUIProps {
    peer: Peer;
    incomingState: IParticipantState;
    outgoingState: IParticipantState;
    setOutgoingState: Handler<Partial<IParticipantState>>;
    returnURL?: string;
    children?: React.ReactNode
}

export function Call({ peer, incomingState, outgoingState, setOutgoingState, returnURL, children }: ICallUIProps) {
    const [ 
        incomingStream, 
        { videoStream: outgoingVideoStream },
        [video, setVideo], 
        [mute, setMute],
        { incomingAudioConnection }
    ] = useMediaConnection({ 
        peer,
        peerId: incomingState.brokerId,
        outgoingState,
        setOutgoingState,
        returnURL
    });
 
    
    return (
        <Box className="call-ui" sx={{ mt: "3em", width: "100%"}}>
            <IncomingStream  {...{ incomingState, incomingStream }} />
            <OutgoingStream {...{outgoingState, outgoingVideoStream}}/>

            <Box className="call-controls optional">
                {children}
            </Box>
            <Box className="call-controls">
                <Fab 
                    aria-label="add" 
                    color={(mute ? "secondary" : "primary")}
                    onClick={() => { setMute(!mute); }}
                    className="control mic"
                >
                    {mute? <MuteIcon /> : <MicIcon/>}
                </Fab>
                <Fab 
                    aria-label="add" 
                    color={(video ? "primary" : "secondary")}
                    onClick={() => { 
                        setOutgoingState({ video: !video });
                        setVideo(!video);
                    }}
                    className="control cam"
                >
                    {video? <VideoCam /> : <VideoCamOff/>}
                </Fab>
                <Fab 
                    aria-label="add" 
                    color={(video ? "primary" : "secondary")}
                    onClick={() => { 
                        setOutgoingState({ video: !video });
                        setVideo(!video);
                    }}
                    className="control cam"
                >
                    {video? <VideoCam /> : <VideoCamOff/>}
                </Fab>
            </Box>
            {incomingAudioConnection?.open? "" : <Loading noBackground={true}/>}
        </Box>
    );

}
