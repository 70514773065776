import React from 'react';
import { Reference } from '@firebase/database-types';
import { useHistory } from 'react-router';
import { useAppSelector } from '../../app/hooks';
import { Handler, Undefinable } from '../utils/Types';
import { IParticipantState } from './Types';


interface IUseConnectionStateProps {
    brokerId: string;
    stateDocRef: Reference;
    isCaller: boolean;
    returnURL?:string;
}

const getIncoming = (isCaller: boolean) => isCaller ? "reciever" : "caller";
const getOutgoing = (isCaller: boolean) => isCaller ? "caller" : "reciever";

const _window: any = window;
const log: any = () => {};

export function useConnectionState ({ brokerId, stateDocRef, isCaller, returnURL } : IUseConnectionStateProps)
: [Undefinable<IParticipantState>, Undefinable<IParticipantState>, Handler<Partial<IParticipantState>>] {
    const [ incomingState, setIncomingState ] = React.useState<IParticipantState>();
    const [ outgoingState, _setOutgoingState ] = React.useState<IParticipantState>();

    const profile = useAppSelector(state => state.firebase.profile);

    const defaultState = React.useMemo(() => ({brokerId, isCaller, uid: profile.uid, name: profile.name, profilePic: profile.profilePic}),
    [brokerId, isCaller, profile.name, profile.profilePic, profile.uid])
    

    const setOutgoingState = React.useMemo(() => () => {
        log("setting state doc");
        stateDocRef.child(getOutgoing(isCaller)).set({ ...defaultState});
    }, [defaultState, isCaller, stateDocRef])

    const updateOutgoingState = React.useMemo(() => (state: any) => {
        log("updating state doc");
        stateDocRef.child(getOutgoing(isCaller)).update({ ...state, ...defaultState});
    }, [defaultState, isCaller, stateDocRef])

    const history = useHistory();
    const unblock = React.useMemo(() => history.block("Are you sure you want to leave?"), [history]);

    React.useEffect(() => { 
        if (typeof _window.unblock === "function") {
            _window.unblock();
        }
        _window.unblock =  unblock;
        
        return () => {
            _window.unblock = undefined;
            unblock();
        }
    }, [unblock]);
    // making sure we don't wait forever for the doc
    React.useEffect(() => {
        if(!incomingState?.brokerId) {
            const timeout =  setTimeout(() => {
                if (typeof _window.unblock === "function") _window.unblock();
                history.push(returnURL || "/public");
            }, 30000);
            return () => { clearInterval(timeout); };
        }
    }, [history, incomingState?.brokerId, returnURL])

    React.useEffect(() => {
        setOutgoingState();
    }, [setOutgoingState]);


    // overlooking and updating doc and state
    React.useEffect(() => {
        const incomingAlias = getIncoming(isCaller);
        const outgoingAlias = getOutgoing(isCaller);
            
        log("setting outgoing state");
        
        // setOutgoingState();
        let active = true;
        stateDocRef.on('value', (snapshot) => {
            const incomingSnapshot = snapshot.child(incomingAlias);
            const outgoingSnapshot = snapshot.child(outgoingAlias);

            if (outgoingSnapshot.exists()) {
                log("setting internal outgoing state");
                if (active) _setOutgoingState(outgoingSnapshot.val());
            } else {
                log("setting remote outgoing state");
                setOutgoingState();
            }
            if (active) 
            setIncomingState(oldState => { 
                if(incomingSnapshot.exists()) {
                    log("setting internal incoming state");

                    return incomingSnapshot.val();
                } else if (oldState?.brokerId) {
                    log("removing outoing snapshot");
                    
                    outgoingSnapshot.ref.remove();
                    if (typeof unblock === "function") unblock();
                    history.push(returnURL || "/public");
                    return undefined;
                }

                return undefined;
            });

        });

        return () => {
            active = false;
            stateDocRef.off('value');
        }
    }, [history, isCaller, returnURL, setOutgoingState, stateDocRef, unblock]);

    // making sure the doc is refreshed 
    React.useEffect(() => {
        // if (!isCaller) {
        //     stateDocRef.remove(); 
        // }
        stateDocRef.child(getOutgoing(isCaller)).onDisconnect().remove();

        return  () => {
            log("unmounted!! removing outoing snapshot");
            stateDocRef.child(getOutgoing(isCaller)).onDisconnect().cancel();
            stateDocRef.child(getOutgoing(isCaller)).remove();

            if(isCaller) {
                stateDocRef.remove();
            };
        }
    }, [isCaller, stateDocRef]);

    return [incomingState, outgoingState,  updateOutgoingState];
}