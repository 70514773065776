import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useFirebase } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { useAppSelector } from '../../app/hooks';
import { IChatMessage, IDBChatMessage } from '../../models/chat';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import { IProfileBrief } from '../../models/user';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { AvatarDisplay } from '../utils/UserAvatar';
import { MONTHS } from '../utils/Consts';

interface IChatProps {
    
}
type TypedMessage = IChatMessage & { type: "sent" | "recieved" };

export function Chat(props: IChatProps) {
    const { participantId }  = useParams<{ [key: string]: string }>();
    
    const fb = useFirebase();
    const profile = useAppSelector(state => state.firebase.profile);
    const chat = profile.chats[participantId];

    const [ sentMessages, setSentMessages ] = React.useState<IDBChatMessage[]>([]);
    const [ recievedMessages, setRecievedMessages ] = React.useState<IDBChatMessage[]>([]);

    const [ messages, setMessages ] = React.useState<TypedMessage[]>([]);

    const inputRef = React.useRef<any>();

    React.useEffect(() => {
        const dbMessages: any[] = [];   
        sentMessages.forEach(message => dbMessages.push({...message, type: "sent"}));
        recievedMessages.forEach(message => dbMessages.push({...message, type: "recieved"}));

        let messages: TypedMessage[] = dbMessages.map(message => ({ 
            ...message, 
            sentDate: new Date(message.sentDate),
            recievedDate: new Date(message.recievedDate),
            seenDate: new Date(message.seenDate)
        }));

        messages.sort((a, b) => (a.sentDate.getTime() - b.sentDate.getTime()))
        setMessages(messages);
        
    },[sentMessages, recievedMessages]);

    React.useEffect(() => {
        if(profile.chats[participantId].unseenMessage) {
            fb.database().ref(`users/${profile.uid}/chats/${participantId}/unseenMessage`).set(0);
        }
    }, [fb, participantId, profile]);
    
    React.useEffect(() => {
        const sentMessageRef = fb.database().ref(`chats/${profile.uid}/${participantId}/messages`);
        const recievedMessageRef = fb.database().ref(`chats/${participantId}/${profile.uid}/messages`);

        sentMessageRef.on('value', (dataSnapshot) => {
            const messages: IDBChatMessage[] = (dataSnapshot.val() || []);
            setSentMessages(messages); 
        });

        recievedMessageRef.on('value', (dataSnapshot) => {
            const messages: IDBChatMessage[] = (dataSnapshot.val() || []);
            setRecievedMessages(messages);
        });

        return () => {
            sentMessageRef.off('value');
            recievedMessageRef.off('value');
        };
    }, [fb, participantId, profile.uid]);
    
    return (
        <Box sx={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "flex-end"}}>
            <List sx={{maxHeight: "calc(100vh - 350px)", overflowY: "scroll", marginBottom: "3rem" }}>
                {messages.map((message, idx) =>{
                    let messageProfile: IProfileBrief;

                    if (message.type === "sent") {
                        messageProfile = profile;
                    } else {
                        messageProfile = chat.participant;
                        
                    }
                    const timestamp = new Date();
                    let dateString = "";
                    if (message.sentDate.getDay() === timestamp.getDay()) {
                        let time = message.sentDate.getHours() % 12;
                        let AMPM = message.sentDate.getHours() < 12 ? "am" : "pm";

                        if (message.sentDate.getHours() === 12) {
                            time = 12;
                        }

                        dateString = `${time}:${message.sentDate.getMinutes()} ${AMPM}`;
                    } else {
                        dateString = `${message.sentDate.getDate()} ${MONTHS[message.sentDate.getMonth()]}`;
                    }


                    return (
                        <React.Fragment key={idx}>
                            <ListItem>
                                <ListItemAvatar>
                                    <AvatarDisplay sx={{ width: 55, height: 55, mr: 2 }} profile={messageProfile}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<React.Fragment>
                                        {messageProfile.name} 
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            color="text.primary"
                                            sx={{float: "right"}}
                                        >
                                            {dateString}
                                        </Typography>
                                    </React.Fragment>}
                                    secondary={
                                        <React.Fragment>
                                        <Typography
                                            component="div"
                                            variant="body2"
                                            color="text.primary"
                                            sx={{ mb: "0.18rem" }}
                                        >
                                        {message.message}
                                        </Typography>
                                        
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                    );
                })}
                
            </List>

            
            <form onSubmit={(e) => {
                e.preventDefault();
                const db = fb.database();
                
                if (inputRef.current) {
                    const input = inputRef.current;
                    const message = input.value;
                    const sentDate = new Date();
                    const archivedDateIndex = `${sentDate.getFullYear()}-${sentDate.getMonth()}`;

                    let messages = [...(sentMessages || []), { 
                        message,
                        sentDate: sentDate.getTime()
                    } as IDBChatMessage];

                    if (messages.length > 100) {
                        messages.unshift();
                    }

                    const sentMessageRef = db.ref(`chats/${profile.uid}/${participantId}/messages`);
                    const countRef = db.ref(`users/${participantId}/chats/${profile.uid}/unseenMessage`);
                    const archivedMessageRef = db.ref(`chats/${profile.uid}/${participantId}/archivedMessages/${archivedDateIndex}`);

                    inputRef.current.value = "";

                    archivedMessageRef.push();
                    sentMessageRef.set(messages);
                    countRef.set(fb.database.ServerValue.increment(1));

                }
            }}>
                <Grid container>
                    <Grid item xs={10}>
                        <TextField inputProps={{ ref: inputRef }} label="Message" variant="standard" sx={{ width: "100%"}}/>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton aria-label="Send" size="large" type="submit">
                            <SendIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </form>
        
        </Box>
    );
}