import React from "react";
import { useHistory } from "react-router";
import { StateSetter } from "../utils/Types";
import { TIMEOUT } from "../utils/Consts";
import { IRequest, IRequestRef } from "./Types";
import { useAppSelector } from "../../app/hooks";

  
export function useCandidate() : [IRequestRef | undefined, StateSetter<IRequestRef>]{
    const history = useHistory();
    const region = useAppSelector(state => state.firebase.profile.preferredRegion);
    const uid = useAppSelector(state=> state.firebase.auth.uid);
    const [candidate, setCandidate] = React.useState<IRequest>();
    const [candidateRef, setCandidateRef] = React.useState<IRequestRef>();
    
    // synching candidate data
    React.useEffect(() => {
        if (candidateRef?.ref) {
            let active = true;
            candidateRef.ref.on('value', (snapshot) => {
                if (active) {
                    setCandidate(snapshot.val());
                }
            });
            
            return () => {
                active = false;
                candidateRef.ref.off('value');
            }
        }
    }, [candidateRef]);

    // connecting to candidate
    React.useEffect(() => {
      if (!candidate || !candidateRef) return;
      let active = true;
      let timeouts: any[] = [];
      const resetCandidate = () => { setCandidate(undefined); setCandidateRef(undefined); }
      let timestamp = Date.now();
      if (candidateRef?.active) {
        // checking if request is correct
        if ( candidate.request  && candidate.request.uid === uid ) {
          // checking if request has timedout
          if ( candidate.accepted 
            || timestamp - candidate.request.timestamp < TIMEOUT
          ) {
            // checking if candidate has been set
            if (candidate.accepted) {
              // checking if candidate is us
              if (
                candidate.accepted.uid === uid
                && timestamp - candidate.accepted.timestamp < TIMEOUT
              ) {
                if (active) {
                  history.push(`/public/connect/${region}/${candidate.uid}?timestamp=${candidate.accepted.timestamp}&isCaller=true`);
                }
              } else  {
                resetCandidate();
              }
            } else {
              // Setting Timeout to reset candidate if no update came
              // if (active) {
              //   timeouts.push(setTimeout(() => {
              //       if (active) {
              //         resetCandidate();
              //       }
              //     }, Math.abs(timestamp - candidate.request.timestamp + 500)));
              // }
            }
          } else {
            resetCandidate();
          }
          
        } else {
          resetCandidate();  
        }
      } else if (candidate.request) {
        // checking if request is correct
        if (
          candidate.accepted 
          || timestamp - candidate.request.timestamp < TIMEOUT
        ) {
          if (candidate.accepted) {
            // making sure accepted candidate is same as the requested candidate
            if (candidate.accepted.uid === candidate.request.uid && timestamp - candidate.accepted.timestamp < TIMEOUT) {
              if (active) {
                setTimeout(() => {
                  history.push(`/public/connect/${region}/${candidate?.request?.uid}?timestamp=${timestamp}`)
                }, 2000);
                
              }
            } else {
              resetCandidate();
            }
          } else {
            if (active) {
              history.push(`/public/connect/${region}/${candidate.request.uid}?timestamp=${timestamp}`);
              candidateRef.ref.child("accepted").set({ uid: candidate.request.uid, timestamp });
            }
          }
        }  else {
          resetCandidate();
        }
      }

      return () => {
        active = false;
        timeouts.forEach(clearTimeout);
      };
    }, [uid, candidate, candidateRef, history, region]);
  
  
  
  
    return [candidateRef, setCandidateRef];
  }