import React from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { IDictionary } from "../../models/common";
import { MenuItems } from "./MenuItems";
import regions from "./regions.json";
import { NamedValue } from './NamedValue';

interface IRegionSelect {
    value: string;
    setValue: (value: string) => any;
    selectProps?: IDictionary<any>;
    title?: string
}

export function RegionSelect ({value, setValue, selectProps, title}: IRegionSelect) {
    selectProps = selectProps || {};
    title = title || "Region:";
    const regionOptions = regions.map(region => NamedValue(region.Code, `${region.Icon} ${region.Name}`));

    return (
        <FormControl color="secondary">
            <InputLabel id="region-select-label" color="secondary">{title}</InputLabel>
            <Select
                labelId="region-select-label"
                id="region-select"
                label="Region"
                {...selectProps}
                value={value || ""}
                onChange={(e) => { 
                    setValue(e.target.value)
                }}
                color="secondary"
                
            >
                {MenuItems(regionOptions)}
            </Select>
        </FormControl>
    );

}