import React from 'react';
import { useRef } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ClassedTypography } from "../utils/Typography";
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from "react-router";
import { useSnackbar } from '../utils/useSnackbar';
import { getStatement } from '../../strings';

interface IRegisterFormProps {
  inputVariant: "filled" | "standard" | "outlined"
}

const border = "1px solid grey";

export function RegisterForm ({inputVariant}: IRegisterFormProps) {
  let firebase = useFirebase();
  let history = useHistory();
  let [snackbar, {setError}] = useSnackbar();

  let nameInput = useRef<any>(null);
  let emailInput = useRef<any>(null);
  let phoneInput = useRef<any>(null);
  let passwordInput = useRef<any>(null);
  let confirmPasswordInput = useRef<any>(null);
  let privacyInput = useRef<any>(null);
  let newsletterInput = useRef<any>(null);

  return (
      <Box 
        component="form"
        onSubmit={(e: any) => {
          e.preventDefault();

          let password = passwordInput.current?.value;
          let confirmPassword = confirmPasswordInput.current?.value;

          if (password !== confirmPassword) {
            setError("mismatchingPassword");
            return;
          }

          let name = nameInput.current.value;
          let email = emailInput.current.value;
          let privacy = privacyInput?.current?.checked;
         
          if (
              !name || 
              !email || 
              !password || 
              !privacy 
            ) {
            setError("formErrorUserInput");
            return;
          }

          let phone = phoneInput?.current?.value;
          let newsletter = newsletterInput?.current?.checked;
         
          firebase.createUser({
            email,
            password,
            signIn: true
          }, {
            name,
            phone,
            registeredDate: Date.now(),
            newsletter
          }).then(() => {
            console.log("Success");
            history.push('/');
          }).catch((e)=> {
            console.error(e);
            switch(e.code) {
              case "auth/weak-password":
              case "auth/email-already-in-use":
                setError(e.code);
                break;
              default:
                setError("registrationError");
                break;
            }
            
          });
        }}
        sx={{
          px: {
            xl: 8,
            lg: 8,
            md: 8,
            sm: 0,
            xs: 0
          },
          borderLeft: { 
            xl: border,
            lg: border,
            md: border, 
            sm: "none", 
            xs: "none"
          },
          borderTop: { 
            xl: "none",
            lg: "none",
            md: "none",
            sm: border,
            xs: border
          },
          
          pt: {
            xl: 0,
            lg: 0,
            md: 0,
            sm: 8,
            xs: 8
          } 
        }}
      >
          {snackbar}
          <Box className="field">
            <ClassedTypography variant="h5"  text={getStatement("joinUsPrompt")}/>
            <ClassedTypography variant="subtitle1" text={getStatement("joinUs")}/>
          </Box>
          <Box className="field">
            <TextField inputRef={nameInput} required id="Name" label="Name" variant={inputVariant} />
          </Box>
          <Box className="field">
            <TextField inputRef={emailInput} required id="Email" label="Email" type="email" variant={inputVariant} />
          </Box>
          <Box className="field">
            <TextField inputRef={phoneInput} id="Phone" label="Phone Number" type="tel" variant={inputVariant} />
          </Box>
          <Box className="field">
            <TextField  inputRef={passwordInput} required id="password" type="password" label="Password" variant={inputVariant} />
          </Box>
          <Box className="field">
            <TextField  inputRef={confirmPasswordInput} required id="confirmation" type="password" label="Confirm Password" variant={inputVariant} />
          </Box>
          <Box className="field">
            <Box><FormControlLabel control={<Checkbox inputRef={privacyInput} required/>} label={getStatement("privacyAggrementLabel")} /></Box>
            <Box><FormControlLabel control={<Checkbox inputRef={newsletterInput} />} label={getStatement("joinNewsLetterLabel")} /></Box>
          </Box>
          <Button type="submit" color="secondary">{getStatement("createAccount")}</Button>
        </Box>
  );
}