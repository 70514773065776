import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Connect } from '../Connect/Connect';
import { Search } from '../Search/Search';


export function Public() {
    return (
        <Switch>
            <Route path="/public/connect/:region/:userId" component={Connect}/>
            <Route exact path="/public" component={Search} />
        </Switch>
    );

}