import React from 'react';
import { isLoaded } from 'react-redux-firebase';
import { useAppSelector } from '../../app/hooks';

import { Loading } from './Loading';

// Making sure that the Authorizations are Loaded before mounting Childrens
export function AuthIsLoaded({ children }: any) {
    const auth = useAppSelector(state => state.firebase.auth)
    if (!isLoaded(auth)) return <Loading/>;
    return children
}

