import React from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { IDictionary } from "../../models/common";
import { genderOptions } from "./Gender";
import { MenuItems } from "./MenuItems";

interface IGenderSelectProps {
    value: string;
    setValue: (value: string) => any;
    selectProps?: IDictionary<any>;
    title?: string;
}

export function GenderSelect ({value, setValue, selectProps, title}: IGenderSelectProps) {
    selectProps = selectProps || {};
    title = title || "Gender:"
    return (
        <FormControl color="secondary">
            <InputLabel id="gender-select-label" color="secondary">{title}</InputLabel>
            <Select
                labelId="gender-select-label"
                id="gender-select"
                label="Gender"
                {...selectProps}
                value={value || ""}
                onChange={(e) => { setValue(e.target.value)}}
                color="secondary"
            >
                {MenuItems(genderOptions)}
            </Select>
        </FormControl>
    );

}