import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { Provider } from 'react-redux';

import { store } from '../../app/store';
import { firebase } from '../../app/firebase';
import { AuthIsLoaded } from './AuthIsLoaded';

import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#FF6666',
    },
    secondary: {
      main: '#2196f3',
    },
  },
};

const theme = createTheme(themeOptions);


// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users',
    // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
    enableClaims: true // Get custom claims along with the profile
}

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch
    // createFirestoreInstance // <- needed if using firestore
}


interface IProvideAllProps {
    children: any;
}
function ProvideAll({children}: IProvideAllProps): JSX.Element {
    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <AuthIsLoaded>
                    <BrowserRouter>
                        <ThemeProvider theme={theme}>
                        {children}
                        </ThemeProvider>
                    </BrowserRouter>
                </AuthIsLoaded>
            </ReactReduxFirebaseProvider>
        </Provider>
    );
}


export { ProvideAll };


