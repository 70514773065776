import React from 'react';
import Peer from 'peerjs';

export function usePeer() {
    if (!(window as any).peer) {
        (window as any).peer = new Peer({
            config: {
                iceServers: [{
                    urls: [ "stun:ss-turn2.xirsys.com" ]
                 }, {
                    username: "4bJjQrTMtMjqyf3VM4TLmby5vlVarLL9glkSo1SC8kxvZeT3ZfN7yu5yaReiwtRBAAAAAGFfjf5yb25yYW01MTI2",
                    credential: "0fe21eda-27cd-11ec-ad15-0242ac140004",
                    urls: [
                        "turn:ss-turn2.xirsys.com:3478?transport=udp",
                    ]
                 }]
            }
        });
    }
    const [ peer ] = React.useState((window as any).peer);
    const [ brokerId, setBrokerId ] = React.useState(peer.id || "");
    const [error, setError] = React.useState<any>();

    React.useEffect(() => () =>{
        const peer: Peer | undefined = (window as any).peer;
        if (peer) {
            peer.disconnect();
            delete (window as any).peer;
        }
    }, [])

    // attaching all event functions
    React.useEffect(() => {

        const reconnect =  () => {
            setTimeout(() => { 
                peer.reconnect();
            }, 100);
        };

        peer.on('disconnected', reconnect);

        const handleBroker = (id: string) => {
            setBrokerId(id);
        };
        peer.on('open', handleBroker);

        peer.on('error', (err: Error) => {
            setError(err);
        })

        return () => {
            peer.off('disconnected', reconnect);
            peer.off('open', handleBroker);
        };
    }, [brokerId, peer]);

    return [peer, brokerId, error];
}