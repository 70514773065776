import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React from 'react';

export function Home() {
    return (
        <React.Fragment>
            <Box sx={{mt: "5rem", textAlign: "center"}}>
                <Typography variant="h4" >Find Your Love Bird...</Typography>
                <Typography variant="subtitle1" >Press Earth Button to start Searching the World...</Typography>
            </Box>
        </React.Fragment>
    );
}