import React from 'react';
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useHeading } from "../Header/useHeading";
import { IWizardMountProps } from "./shared";

interface ISubscriptionProps extends IWizardMountProps {

}

export function Subscription(props: ISubscriptionProps) {
    useHeading("Subscription");

    return (
        <Box component="form" onSubmit={(e: any) => {
            e.preventDefault();
            if (props.onSubmit)  {
                props.onSubmit();
            }
        }}>
            <Box sx={{ px: "3rem", mb:"2rem"}}>
                <Typography variant="h4">
                    All Done!!
                </Typography>
                <Typography variant="body1">
                    Press Finish to Continue!!
                </Typography>
            </Box>
            {props.wizardNav}
        </Box>
    );

}