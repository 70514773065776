import React from 'react';
import { useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MaterialLink from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { ClassedTypography } from "../utils/Typography";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";

import { getStatement } from '../../strings';
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router";

import { useSnackbar } from "../utils/useSnackbar";




interface ILoginFormProps {
    inputVariant: "filled" | "standard" | "outlined"
}


export function LoginForm({inputVariant}: ILoginFormProps): JSX.Element {
   
    const firebase = useFirebase();
    const history = useHistory();

    const [snackbar, {setError} ] = useSnackbar(); 
    
    const emailInput = useRef<any>(null);
    const passwordInput = useRef<any>(null);
    const rememberInput = useRef<any>(null);

    return (
        <Box sx={{pr: { xl:8, lg: 8, md: 8, sm: 0}, py: 6}} component="form" onSubmit={(e :any) => {
            e.preventDefault();
            let email = emailInput.current?.value;
            let password = passwordInput.current?.value;
            let remember = rememberInput.current?.checked;
            
            firebase.auth().setPersistence(remember? 'local' : 'session');
            
            if (typeof email === "string" && typeof password === "string") {
                firebase.login({ email, password}).then(() => {
                    console.log("successfully logged in");
                }).catch(e => {
                    setError("loginError");
                });
                
            }
        }}>
            {snackbar}
            
            <ClassedTypography variant="h5" component="h3" className="field" text={getStatement("logIn")}/>
            <Box className="field">
                <TextField required inputRef={emailInput} id="email" type="email" label="Email Address" name="email" variant={inputVariant} />
            </Box>
            <Box className="field">
                <TextField required inputRef={passwordInput} id="password" type="password" label="Password" name="password" variant={inputVariant} />
            </Box>
            <Box className="field">
                <FormControlLabel control={<Checkbox  inputRef={rememberInput} name="remember" id="remember"/>} label={getStatement("rememberMe")} />
            </Box>
            <Box className="field">
                <Button type="submit" color="secondary">{getStatement("logIn")}</Button>
            </Box>
            <Box className="forgotten-link">
                <MaterialLink href="#" onClick={() => history.push("/login/recover")} underline="none" color="secondary">
                {getStatement("forgottenPassword")}
                </MaterialLink>
            </Box>

            <Box className="social-button">
                <Button 
                    type="button"
                    variant="outlined"
                    size="large"
                    color="secondary"
                    startIcon={<GoogleIcon/>}
                    onClick={() =>  firebase.login({ 
                        provider: "google", 
                        type: "redirect"
                        
                    }).then(() => {
                        console.log("successfully logged in");
                    }).catch(e => {
                        if (e.code === "auth/account-exists-with-different-credential") {
                            setError("auth/email-already-in-use");
                        } else {
                            console.warn(e);
                        }
                    })}
                > {getStatement("loginWithGoogle")}</Button>
            </Box>
            <Box className="social-button">
                <Button
                    type="button"
                    variant="outlined"
                    size="large"
                    startIcon={<FacebookIcon/>}
                    color="secondary"
                    onClick={() =>  firebase.login({ 
                        provider: "facebook", 
                        type: "redirect"
                    }).then(() => {
                        console.log("successfully logged in");
                    }).catch(e => {
                        if (e.code === "auth/account-exists-with-different-credential") {
                            setError("auth/email-already-in-use");
                        } else {
                            console.warn(e);
                        }
                    })}
                > {getStatement("loginWithFacebook")}</Button>
            </Box>

            </Box>
    );
}