import React from 'react';
import { getStatement } from '../../strings';
import { useHeading } from '../Header/useHeading';
import { useAppSelector } from '../../app/hooks';
import { useActiveSearch } from './ActiveSearch';
import { usePassiveWait } from './PassiveWait';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useCandidate } from './Candidate';

export function Search() {
    useHeading(getStatement("title"));  
    const uid = useAppSelector(state => state.firebase.auth?.uid);
    const profile = useAppSelector(state => state.firebase.profile);
  
    const [candidateRef, setCandidateRef] = useCandidate();
    const searchProps = { uid, profile, candidateRef, setCandidateRef };
    
    useActiveSearch(searchProps);
    usePassiveWait(searchProps);
  
    return (
      <React.Fragment>  
        <Box sx={{ py: '5em'}}>
          <Typography variant="h5" sx={{ mb: '3rem'}}>Looking for Connections:</Typography>
          <CircularProgress />
        </Box>
      </React.Fragment>
    );
}