import React from 'react';
import { DataSnapshot } from '@firebase/database-types';
import { IPublicConnectionProps, IRequestComm } from './Types';
import { useRegionalRequestRef } from '../utils/PublicRequests';

const _window: any = window;

const log: any = console.log;

interface IPassiveWaitProps extends IPublicConnectionProps { }

export function usePassiveWait({ profile, uid, candidateRef, setCandidateRef }: IPassiveWaitProps) {
    const ref = useRegionalRequestRef(profile.preferredRegion, profile.gender, profile.preferredGender, profile.uid);

    React.useEffect(() => {
        if (candidateRef || !ref ) return;
        _window.connected = false;
        let active = true;
        let timeouts: any[] = [];

        ref.on('value', (snapshot: DataSnapshot) => {
            let request: IRequestComm | undefined = snapshot.child("request").val();
            // making sure someone else hasn't updated the value
            if (request?.uid && active) {
                setCandidateRef({ ref, active: false});
            }
        });

        log("setting timeout for the ref update");

        timeouts.push(setTimeout(() =>{
            if (active) {
                log("setting passive doc");
                ref.set({ uid, timestamp: Date.now() });
                ref.onDisconnect().remove();
            }
        } , 3000 * Math.random()));
        

        return () => {
            active = false;
            ref.off('value');
            setTimeout(() => {
                ref.onDisconnect().cancel();
                ref.remove();
            }, 3000);
            timeouts.forEach(clearTimeout);
        }

    }, [candidateRef, ref, setCandidateRef, uid]);

}