import React from 'react';
import Box from "@mui/material/Box";
import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { IProfile } from "../../models/user";
import { useHeading } from "../Header/useHeading";
import { Loading } from "../utils/Loading";
import { useSnackbar } from "../utils/useSnackbar";
import { IWizardMountProps } from "./shared";
import Container from "@mui/material/Container";
import { GenderSelect } from "../utils/GenderSelect";
import { useFirebase } from "react-redux-firebase";
import { RegionSelect } from '../utils/RegionSelect';

interface IPreferenceProps extends IWizardMountProps {

}

function PreferenceDisplay({profile, ...props}: IPreferenceProps & { profile: IProfile}) {
    useHeading("Preference");
    const firebase = useFirebase();
    const [snackbar, {setSuccess, setError}] = useSnackbar();
    const [loading, setLoading] = useState(false);
    
    const [preferredRegion, setPreferredRegion] = useState(profile.preferredRegion || "");
    const [preferredGender, setPreferredGender] = useState(profile.preferredGender || "");
   
    const updateProfile = (newData: any) => {
        if (!props.wizardNav) {
            firebase.updateProfile({  preferredRegion, preferredGender, ...newData});
        }
    }


    return (
        <Container>
            <Box component="form" onSubmit={async (e: any) => {
                e.preventDefault();
                setLoading(true);
                try {
                    await firebase.updateProfile({  preferredRegion, preferredGender});
                    setSuccess("savedSuccessfully");
                    console.log("Success");
                    
                    if (props.onSubmit)  {
                        setTimeout((submit: () => any) => submit(), 10, props.onSubmit);
                    }
                } catch (e) {
                    setError("cannotUpdateProfile");
                }
                
                setLoading(false);
            }} sx={{mt: "5rem"}}>
                {snackbar}
                
                <Box className="field-wrapper">
                    <RegionSelect 
                        value={preferredRegion} 
                        setValue={(newData) => { 
                            setPreferredRegion(newData);
                            updateProfile({ preferredRegion: newData});
                        }} 
                    />
                </Box>

                <Box className="field-wrapper">
                    <GenderSelect
                        value={preferredGender}
                        setValue={(newData) => { 
                            setPreferredGender(newData);
                            updateProfile({ preferredGender: newData});
                        }} 
                        title="Opposite Gender"
                    />
                </Box>

                {props.wizardNav}
                {loading? <Loading/> : "" }
                
            </Box>
        </Container>
    );

}

export function Preference(props: IPreferenceProps) {
    const profile = useAppSelector(state=> state.firebase.profile);
    if (profile.isLoaded) {
        return <PreferenceDisplay profile={profile} {...props} />
    } else {
        return <Loading/>
    }
}