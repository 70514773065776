import React from 'react';
import Peer, { MediaConnection } from 'peerjs';
import { IStream, useUserMedia } from './UserMedia';
import { Handler, StateSetter } from '../utils/Types';
import { TIMEOUT } from '../utils/Consts';
import { useHistory } from 'react-router';
import { State } from './Types';

const _window: any = window;
_window.mediaConnections = [];
_window.mediaStreams = [];

enum ConnectionType {
    "audio",
    "video"
    
}

export function useSubMediaConnection(): 
[ 
    State<MediaConnection | undefined>,
    MediaStream | undefined,
    any,
    any
] {
    const [connection, _setConnection] = React.useState<MediaConnection>();
    const [stream, setStream] = React.useState<MediaStream>();
    const [error, setError] = React.useState<any>();
    const setConnection = React.useMemo((): StateSetter<MediaConnection> => (conn) => {
        _window.mediaConnections.push(conn);
        _setConnection((oldConn) => {
            let result: MediaConnection | undefined;
            if (typeof conn === "function") {
                result = conn(oldConn);   
            } else {
                result = conn;
            }
            if (result !== oldConn && oldConn) {
                oldConn.close();
            } 
            return result;
            
        });
    }, [_setConnection]);
    // hooking stream event
    React.useEffect(() => {
        if (connection?.open) {
            const incomingStreamHandler = (stream: MediaStream) => {
                log("recieved stream");
                
                setStream(stream);
            };
            connection.on('stream', incomingStreamHandler);
         
            return () => {
                connection.off('stream', incomingStreamHandler);
            }
        }
    }, [connection]);


    // hooking close and error functions
    React.useEffect(() => {
        if (connection?.open) {
            const closeHandler = () => {
                setStream(undefined);
                connection.close();
                _setConnection(undefined);
            };
            connection.on('close', closeHandler);

            const errorHandler = (e: any) => {
                setError(e);
            }
            connection.on('error', errorHandler);


            return () => {
                connection.off('close', closeHandler);
                connection.off('error', errorHandler);
            }

        }
    }, [connection]);

    return [
        [ connection, setConnection],
        stream,
        error,
        { setError }
    ];
}

interface IUseMediaConnection {
    peer: Peer;
    peerId: string;
    video?: boolean;
    outgoingState: any;
    setOutgoingState: Handler<any>;
    returnURL?: string;
}
const log: any = () => {};

export function useMediaConnection({ peer, peerId, outgoingState, returnURL, setOutgoingState }: IUseMediaConnection):
[
    IStream,
    IStream,
    State<boolean>,
    State<boolean>,
    any
] {
    const [outgoingStream, vidControl, muteControl ] = useUserMedia();

    const [[incomingAudioConnection, setIncomingAudioConnection],incomingAudioStream] = useSubMediaConnection();
    const [[, setOutgoingAudioConnection]] = useSubMediaConnection();
    
    const [[incomingVideoConnection, setIncomingVideoConnection], incomingVideoStream] = useSubMediaConnection();
    const [[, setOutgoingVideoConnection]] = useSubMediaConnection();

    
    const [, setPacketStat] = React.useState<number[]>([]);

    const history = useHistory();

    // answering incoming connection
    React.useEffect(() => {
        const callHandler = function(connection: MediaConnection) {
            const connectionType = connection.metadata?.type;
            

            if (
                connection.peer === peerId &&
                (
                    connectionType === ConnectionType.audio 
                    || connectionType === ConnectionType.video
                )
            ) {
                if (connectionType === ConnectionType.audio) {
                    setIncomingAudioConnection(connection);
                } else {
                    setIncomingVideoConnection(connection);
                }

                connection.answer();
            } else {
                connection.close();
            }
            
        };
        peer.on('call', callHandler);
        return () => peer.off('call', callHandler);
    }, [peer, peerId, setIncomingAudioConnection, setIncomingVideoConnection])

    // starting audio connection
    React.useEffect(() => {
        const audioStream = outgoingStream.audioStream;
        if (audioStream) {
            setOutgoingAudioConnection(peer.call(peerId, audioStream, {
                metadata: {
                    type: ConnectionType.audio
                }
            }));
        }
    }, [outgoingStream.audioStream, peer, peerId, setOutgoingAudioConnection])

    // starting video connection
    React.useEffect(() => {
        const videoStream = outgoingStream.videoStream;
        const [hasVideo] = vidControl;
        if (videoStream) {
            if(hasVideo) {
                setOutgoingVideoConnection((oldConn) => {
                    if (oldConn?.peer === peerId) return oldConn;

                    return peer.call(peerId, videoStream, {
                        metadata: {
                            type: ConnectionType.video
                        }
                    })
                });
            } else {
                setOutgoingVideoConnection(undefined);
            }
        }
    }, [outgoingStream.videoStream, peer, peerId, setOutgoingVideoConnection, vidControl])


    // timeout when connection is lost
    React.useEffect(() => {
        if (incomingAudioConnection?.open) {
            const update = setInterval(() => {    
                if (incomingAudioConnection?.peerConnection)
                incomingAudioConnection.peerConnection.getStats().then(stats => {
                    const recievedPacketCounts: number[] = [];
                    stats.forEach(stat => {
                        if (!isNaN(stat.packetsReceived)) {
                            recievedPacketCounts.push(Number(stat.packetsReceived));
                        } 
                    });
                    
                    // log(recievedPacketCounts);
                    setPacketStat(oldPacketStat => {
                        if (oldPacketStat.length === 0 || oldPacketStat.some((count, idx) => count !== recievedPacketCounts[idx])) {
                            return recievedPacketCounts;
                        }
                        log("connection inactive, reconnecting");
                        setIncomingAudioConnection(undefined);
                        setOutgoingAudioConnection(undefined);
                        return oldPacketStat;
                    });
                });
            }, TIMEOUT);
            return  () => {
                clearInterval(update);
            };
        } else {
            log("re-routing timeout set");
            const timeout = setTimeout(() => {
                log("TIMED OUT! RE-ROUTING");
                if (typeof _window.unblock === "function") _window.unblock();
                history.push(returnURL || "/public");
            }, incomingAudioStream? TIMEOUT : 30000);
            return () => { clearTimeout(timeout); log("re route timeout unset;"); };
        }
    }, [
        history,
        incomingAudioConnection,
        incomingAudioStream,
        returnURL,
        setIncomingAudioConnection,
        setOutgoingAudioConnection
    ]);


    // clearing all conns;
    React.useEffect(() => () => {
        let conn: any;
        // eslint-disable-next-line no-cond-assign
        while (conn = _window.mediaConnections.pop()) {
            if (conn?.open) {
                conn.close();
            }
        }
    }, []);

 
    return [
        { audioStream: incomingAudioStream, videoStream: incomingVideoStream }, 
        outgoingStream,
        vidControl,
        muteControl,
        { 
            incomingAudioConnection,
            incomingVideoConnection
        }
    ];
}