import React from 'react';
import { Reference } from '@firebase/database-types';
import Peer from "peerjs";
import { useLocation, useParams } from "react-router";
import { Loading } from "../utils/Loading";
import { Call } from "./Call";
import { useConnectionState } from './ConnectionState';
import { useAppSelector } from '../../app/hooks';
import { usePeer } from './Peer';
import { useRegionalRequestRef } from '../utils/PublicRequests';
import { FriendRequest } from './FriendRequests';


interface IStateConnectProps extends IConnectProps {
    peer: Peer;
    brokerId: string;
    stateDocRef: Reference;
    isCaller: boolean;
    isPrivate?:boolean;
    returnURL?: string;
}

export function StateConnect({ peer, brokerId, stateDocRef, isCaller, isPrivate, returnURL }: IStateConnectProps) {
    const [ incomingState, outgoingState, setOutgoingState] = useConnectionState({ brokerId, stateDocRef, isCaller, returnURL });

    if (incomingState && outgoingState && incomingState?.brokerId) {
        const callParams = {peer, incomingState, outgoingState, returnURL, setOutgoingState, isPrivate };
        
        return (
            <Call {...callParams}> 
                <FriendRequest 
                    isPrivate={!!isPrivate}
                    incomingState={incomingState}
                    incomingFriend={!!incomingState?.friend}
                    outgoingFriend={!!outgoingState?.friend}
                    incomingCaller={incomingState?.name || ""}
                    setOutgoingFriend={function (friend: boolean): void {
                        setOutgoingState({friend});
                    } }
                />
            </Call>
        );
    } else {
        return <Loading noBackground={true}/>
    }
    
}

interface IConnectProps {
    isPrivate?:boolean;
}

export function Connect (props: IConnectProps ) {
    const { region, userId }  = useParams<{ [key: string]: string }>();
    const [peer, brokerId] = usePeer();
    
    const location = useLocation();
    const isCaller = React.useMemo(() => location.search.includes("isCaller=true"), [location]);

    const uid = useAppSelector(state => state.firebase.auth.uid);

    const stateDocRef = useRegionalRequestRef(region, "connect", isCaller ? uid : userId, isCaller ? userId : uid );
    
    if (peer && brokerId && stateDocRef) {
        return <StateConnect {...{ peer, brokerId, isCaller, stateDocRef, ...props }} />;
    } else {
        return <Loading noBackground={true}/>
    }

}