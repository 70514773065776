import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectTitle, setTitle } from "./headerSlice";

export function useHeading(title: string) {
    const titleState = useAppSelector(selectTitle);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if (title !== titleState) {
            dispatch(setTitle(title));
        }
    }, [dispatch, title, titleState]);
}